<template>
  <div class="news__lists__contianer">
    <div class="lists__inner">
      <div class="page__title scrollObj">news</div>
      <div class="news__lists-items__container">
        <div class="news__lists-items__inner">
          <div
            class="news__list__item scrollObj big"
            @click="onDetail"
            :data-id="item.id"
            v-for="(item, index) in lists"
            :key="index"
            :class="[$bowser.mobile ? '' : 'pcHover']"
          >
            <div class="item__inner">
              <div class="item__cover">
                <img :src="item.inner_big_img" alt="" />
              </div>
              <div class="item__info">
                <div class="info__title" ref="wrapper">
                  <div class="scroll_txt">
                    <div class="txtsp">
                      <p class="txt">{{ item.title }}</p>
                    </div>
                    <div class="content">
                      <p class="txt">{{ item.title }}</p>
                      <p class="txt">{{ item.title }}</p>
                    </div>
                    <p class="txt hidden" ref="txt">{{ item.title }}</p>
                  </div>
                </div>
                <div class="info__publish">{{ item.publish }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import events from '@/utils/eventBus';
import { news } from '@/api/data';

export default {
  name: 'views-news',
  data() {
    return {
      lists: [
        {
          id: 1,
          cover: require('@/assets/images/demo1/news/1.png'),
          title: '飞视快讯 | 荣获悉尼 SYDNEY DESIGN AWARDS 大奖诸项殊荣',
          publish: 'September .20.2021',
          shape: 'big',
        },
        {
          id: 2,
          cover: require('@/assets/images/demo1/news/2.png'),
          title: '飞视快讯｜ 荣获韩国 K-DESIGN AWARD 设计大奖金奖',
          publish: 'September .20.2021',
          shape: 'big',
        },
        {
          id: 3,
          cover: require('@/assets/images/demo1/news/3.png'),
          title: '飞视喜讯 | 飞视设计成都分公司 - 开业大吉！',
          publish: 'September .20.2021',
          shape: 'big',
        },
        {
          id: 4,
          cover: require('@/assets/images/demo1/news/n-4.jpg'),
          title: '飞视快讯｜荣获2021德国 ICONIC AWARDS — SELE',
          publish: 'September .20.2021',
          shape: 'small',
        },
        {
          id: 5,
          cover: require('@/assets/images/demo1/news/n-5.jpg'),
          title: '飞视快讯 | 荣获法国 Novum Design Award 设计大奖金奖',
          publish: 'September .20.2021',
          shape: 'small',
        },
        {
          id: 6,
          cover: require('@/assets/images/demo1/news/n-6.jpg'),
          title: '飞视快讯 | 荣获 2021 IPA英国国际房地产奖 AWARD WINNER',
          publish: 'September .20.2021',
          shape: 'small',
        },
        {
          id: 7,
          cover: require('@/assets/images/demo1/news/n-7.jpg'),
          title: '飞视快讯｜荣获2021美国 MUSE Design Awards 金奖',
          publish: 'September .20.2021',
          shape: 'small',
        },
        {
          id: 8,
          cover: require('@/assets/images/demo1/news/n-8.jpg'),
          title: '飞视快讯｜荣膺伦敦 LICC 2020 Professional Official Selection',
          publish: 'September .20.2021',
          shape: 'small',
        },
        {
          id: 9,
          cover: require('@/assets/images/demo1/news/n-9.jpg'),
          title: '飞视快讯｜荣膺 BERLIN Design Awards 2021 柏林设计大奖银奖金奖',
          publish: 'September .20.2021',
          shape: 'small',
        },
        {
          id: 10,
          cover: require('@/assets/images/demo1/news/n-10.jpg'),
          title: '飞视快讯｜荣膺法国双面神「GPDP AWARD」金奖、国际创新设计奖',
          publish: 'September .20.2021',
          shape: 'small',
        },
        {
          id: 11,
          cover: require('@/assets/images/demo1/news/n-11.jpg'),
          title: '飞视快讯｜荣获2021美国 MUSE Design Awards 金奖',
          publish: 'September .20.2021',
          shape: 'small',
        },
        {
          id: 12,
          cover: require('@/assets/images/demo1/news/12.png'),
          title: '飞视快讯｜荣获 NEW YORK Design Awards 2020 纽约设计大奖银奖',
          publish: 'September .20.2021',
          shape: 'big',
        },
        {
          id: 13,
          cover: require('@/assets/images/demo1/news/13.png'),
          title: '飞视快讯 | 荣获2020美国MUSE Design Awards铂金奖',
          publish: 'September .20.2021',
          shape: 'big',
        },
        {
          id: 14,
          cover: require('@/assets/images/demo1/news/14.png'),
          title: '飞视快讯｜荣获法国 Novum Design Award 设计大奖金奖',
          publish: 'September .20.2021',
          shape: 'big',
        },
      ],
      txtMoving: false,
      currentMovingTxt: 0,
      shapeMap: [
        'big',
        'big',
        'big',
        'small',
        'small',
        'small',
        'small',
        'small',
        'small',
        'small',
        'small',
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        this.getmore();
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    init() {
      this.$nextTick(() => {
        this.$refs.txt.forEach((item, index) => {
          if (item.offsetWidth > this.$refs.wrapper[index].offsetWidth) {
            this.$refs.wrapper[index].classList.add('move');
          }
        });
        events.$emit('pageMounted');
      });
    },
    txtMove(e) {
      console.log(e);
      // const obj = e.currentTarget;
      // const { id } = obj.dataset;
      // const container = obj.querySelector('.info__title');
      // const scrollBody = obj.querySelector('.scroll_txt');
      // const htmlTxt = obj.querySelector('.txt');
      // if (container.offsetWidth < htmlTxt.offsetWidth) {
      // }
    },
    onDetail(e) {
      const { id } = e.currentTarget.dataset;
      this.$router.push({
        path: `/news/${id}`,
      });
    },
    getmore() {
      const that = this;
      news().then((res) => {
        const lists = res.data.list;
        that.lists = lists;
        that.init();
      });
    },
  },
};
</script>

<style></style>
